import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
    .directive('pieChartField', pieChartFieldDirective)
    .directive('pieChartFieldPrint', pieChartFieldPrintDirective)
    .directive('pieChartFieldBuild', pieChartFieldBuild)
    .service('PieChartField', PieChartField);

pieChartFieldDirective.$inject = ['Configuration'];

/**
 * Directive object that will build the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function pieChartFieldDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/pie-chart/field.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

pieChartFieldPrintDirective.$inject = ['Configuration'];

/**
 * Directive that will show the field result in the table.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function pieChartFieldPrintDirective(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/pie-chart/print.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}

PieChartField.$inject = [];

/**
 * Service constructor.
 * @constructor
 */
function PieChartField() {

    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try {
            let initialParams = {
                'chartFieldList': {
                    'validation': {
                        'required': true,
                        'validationResponse': 'Please add at least one field for the chart to detect.',
                        'requiredType': 'listMustHaveOne'
                    }
                }
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }

    service._isPieChartEnabled = false;

    // Method that will turn on the pieChart
    service.pieChartOn = function () {
        service._isPieChartEnabled = true;
    }

    // Method that will turn off the pieChart
    service.pieChartOff = function () {
        service._isPieChartEnabled = false;
    }

    // Method that will return the status in a text format
    service.getPieChartButtonAction = function () {
        return service.isPieChartEnabled() ? 'pieChartOn' : 'pieChartOff';
    }

    // This will say if the pieChart is enabled
    service.isPieChartEnabled = function () {
        return service._isPieChartEnabled === true;
    }

    /**
     * Method that will do the actions of the
     * semaphore click, that can be the opposite
     * status of the field _isPieChartEnabled.
     */
    service.click = function () {
        service._isPieChartEnabled
            ? service.pieChartOff()
            : service.pieChartOn();
    }

    service._buildDataset = function (fieldName, dataSet) {
        return [{
            "label": fieldName,
            "data": dataSet,
            "backgroundColor": ["rgb(34,139,34)", "rgb(255, 99, 132)", "rgb(255, 205, 86)", "rgb(155, 205, 26)"],
            textStyle: {
                "color": "#ffffff",
                fontFamily: "Muli, sans-serif"
            }

        }];
    }

    service._buildParams = function (labels, fieldName, dataSet, label) {
        return {
            "type": "doughnut",
            "data": {
                "labels": labels,
                "datasets": service._buildDataset(fieldName, dataSet),
                legend: {
                    textStyle: {
                        "color": "#ffffff",
                        fontFamily: "Muli, sans-serif"
                    }
                }
            },
            options: {
                legend: {
                    display: label,
                    labels: {
                        fontColor: "white",
                        fontFamily: "Muli, sans-serif"
                    }
                },
                segmentShowStroke: false,
                cutoutPercentage: 60,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                }
            }
        };
    }

    service._loadData = function (labels, dataToAdd) {
        let data = [];
        angular.forEach(labels, function (labelFieldSlug) {
            data.push(dataToAdd[labelFieldSlug]);
        });
        return data;
    }

    /**
     * Main construction of the pie chart, this contains behaviour
     * to show the pie chart with labels
     *
     * @param field
     * @param dataToAdd
     * @param withLabel
     */
    service.buildPieChart = async function (field, dataToAdd, withLabel, scanStationFieldIdsToField) {

        const Chart = (await import('chart.js')).default;

        let fieldName = field.field_name;
        let fieldSlug = field.field_slug;
        let fieldData = field.chartFieldListArray;
        let pieChart;
        let labels = [];
        angular.forEach(field.chartFieldListArray, function (fieldId) {
            labels.push(scanStationFieldIdsToField[fieldId].field_slug)
        });

        let data = service._loadData(labels, dataToAdd);
        let pieChartParameters = service._buildParams(labels, fieldName, data, withLabel);

        // sometimes this rule were null, so to try to avoid to load a pie-chart that is null
        if(document.getElementById("pie-chart-" + fieldSlug) != null){
            pieChart = new Chart(document.getElementById("pie-chart-" + fieldSlug), pieChartParameters);
        }


        return true;
    }

    /**
     * Function that will print in the table information about the
     * pie chart, this has a basic behaviour to remove the labels.
     * @param field
     * @param row
     * @returns {boolean}
     */
    service.printPieChart = async function (field, row, scanStationFieldIdsToField) {

        const Chart = (await import('chart.js')).default;

        let fieldName, fieldSlug = row.cartolytics_entry_row_id;
        let fieldData = field.chartFieldListArray;
        let labels = [];
        let fieldSlugs = [];
        angular.forEach(field.chartFieldListArray, function (fieldId) {
            labels.push(scanStationFieldIdsToField[fieldId].field_slug);
        });

        let dataToAdd = {};

        // we must clean the row to get the correct data
        angular.forEach(row, function (value, index) {
            if (labels.includes(index)) {
                dataToAdd[index] = isNaN(parseInt(value)) ? 0 : value;
            }
        });

        let data = service._loadData(labels, dataToAdd);
        let pieChartParameters = service._buildParams(labels, fieldName, data, false);
        // TODO remove setTimeout in V.3.0.1+, requires understanding of why element is not existing yet
        setTimeout(function() {
            let pieChart = new Chart(document.getElementById("pie-chart-print-" + fieldSlug), pieChartParameters);
        }, 1000);


        return true;
    }
}

/**
 * Directive object that will build or edit the field.
 *
 * @returns {{controller: string, restrict: string, templateUrl: string}}
 * @constructor
 */
function pieChartFieldBuild(Configuration) {
    let directive = this;
    let version = Configuration.getVersion();
    let random = Configuration.getRandomValue();
    let templateUrl = 'components/field/template/pie-chart/build-edit.html?v=' + version + 'rand=' + random;

    return ({
        controller: "FieldController",
        restrict: "A",
        templateUrl: templateUrl
    });
}
