import * as angular from 'angular';

"use strict";

angular.module('FieldModule')
       .service('loggedInUserInfoField', loggedInUserInfoField);

/**
 * Service constructor.
 * @constructor
 */
function loggedInUserInfoField() {
    let service = this;

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getInitialParams = async function () {
        try
        {
            let initialParams = {
                'userInfoRequired': {
                    'validation' : {
                        'required': true,
                    }
                },
                'preventUpdateWhenEditingRecord': {
                    'validation' : {
                        'required': false,
                    }
                },
            }

            return initialParams;

        } catch (e) {
            console.log(e);
        }
    }
}
