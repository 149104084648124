import * as angular from 'angular';

"use strict";

angular.module('ManageInactivityModule')
    .controller('ManageInactivityController', ManageInactivityController);

// Injection of each class
ManageInactivityController.$inject = ['ManageInactivityFactory', '$scope', 'ScopeAndLocal'];

// Controller Constructor

function ManageInactivityController(ManageInactivityFactory, $scope, ScopeAndLocal) {
    let vm = this;

    vm.$onInit = function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));

            angular.forEach(appSettingParsed.data, function (settingEntry) {
                if (settingEntry.setting_key == 'inactivity_timeout') {
                    vm.timeout = parseInt(settingEntry.setting_value);
                }
            })

        } catch (error) {
            console.log(error);
        }
    }
}

export default ManageInactivityController;
