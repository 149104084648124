import * as angular from 'angular';
import * as $ from "jquery";
import Language from "components/languages/controllers/language";

"use strict";

angular.module('LanguagesModule')
    .controller('LanguagesController', LanguagesController);

// Injection of each class
LanguagesController.$inject = ['$scope', 'ScopeAndLocal', 'LanguagesFactory', 'Data', 'URLService'];

// Controller Constructor
function LanguagesController($scope, ScopeAndLocal, LanguagesFactory, Data, URLService) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            // console.log('Start: Languages Controller');
            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');
            vm.languagesFactory = LanguagesFactory;
            vm.appSettings = vm.bootstrap.appSettings;
            vm.urlService = URLService;
            vm.popupOpen = false;
            vm.languages = LanguagesFactory.languages;


        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will return the language options.
     */
    vm.getLanguagesOptions = () => {
        return LanguagesFactory.languages;
    }

    /**
     * Method that will be updating the language.
     *
     * @param languageSetting
     */
    vm.updateLanguageSetting = async (languageSetting, status) => {
        try {
            vm.languageSettingToUpdateTo = languageSetting;

            // TODO: change to language service update language settings
            await Data.updateLanguageSetting(languageSetting, status);
            await vm.languagesFactory.bootstrapLanguages( true);

            // this part sets the selected language name
            let selectedLanguageName = LanguagesFactory.getLanguageByCode(languageSetting)
            vm.appSettings.language.name = selectedLanguageName;

        } catch (e) {
            console.log(e);
        } finally {
            vm.popupOpen = false;
            $scope.$apply()
        }

    }

    /**
     * This will toggle the switch from open to close.
     */
    vm.toggle = () => {
        vm.popupOpen = !vm.popupOpen;
        return vm.popupOpen;
    }

}

export default LanguagesController;