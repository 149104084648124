import * as angular from 'angular';

"use strict";

angular
    .module('LoginAttemptsModule')
    .factory('LoginAttemptsFactory', LoginAttemptsFactory)
    .config(LoginAttemptsConfiguration);


/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function LoginAttemptsConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

LoginAttemptsFactory.$inject = ['$http', 'Configuration'];

function LoginAttemptsFactory($http, Configuration) {

    let factory = this

    return factory
}
