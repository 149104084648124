import * as angular from 'angular';

"use strict";

angular
    .module('ManageInactivityModule')
    .factory('ManageInactivityFactory', ManageInactivityFactory)
    .config(ManageInactivityConfiguration);


/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function ManageInactivityConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

ManageInactivityFactory.$inject = ['$http', 'Configuration'];

function ManageInactivityFactory($http, Configuration) {

    let factory = this

    return factory
}
